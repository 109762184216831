<template>
  <div id="app">
    <nav class="navbar navbar-expand-md navbar-light bg-light mb-5">
      <div class="container">
        <a class="navbar-brand" href="/">Recept</a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent"></div>
      </div>
    </nav>

    <div class="container">
      <app-recipe-card v-for="recipe in recipes" :key="recipe.id" :recipe="recipe"></app-recipe-card>
    </div>
  </div>
</template>

<script>
import json from './data.json'
import AppRecipeCard from './components/AppRecipeCard'

export default {
  name: 'App',

  data () {
    return {
      recipes: json
    }
  },

  components: {
    AppRecipeCard
  },

  methods: {
    ratingsArray (rating) {
      return Array.from({ length: parseInt(rating) }, (x, i) => i)
    }
  },

  mounted () {

  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,700;1,400&display=swap');
@import url('./assets/bootstrap.min.css');

* {
  font-family: 'DM Sans', sans-serif !important;
}

.btn.btn-nice {
  border-radius: 5px !important;
  font-weight: bold;
}

.recipe-image {
  height: 150px;
  width: 150px;
  object-fit: cover;
}

.outside-buttons {
  display: none;
}

.inside-buttons {
  display: flex;
}

@media screen and (max-width: 576px) {
  .recipe-image {
    height: 100px;
    width: 100px;
    object-fit: cover;
  }

  .outside-buttons {
    display: flex;
  }

  .inside-buttons {
    display: none;
  }
}
</style>
