<template>
  <div class="recipe card mb-3" style="max-width: 720px;">
    <div class="g-0">
      <div class="card-body">
        <div class="d-flex">
          <div class="">
            <img class="recipe-image" :src="recipe.image_url" alt="">
          </div>

          <div class="ml-3 d-flex w-100 flex-column align-items-start">
            <div class="mb-auto">
              <h5 class="">{{recipe.title}}</h5>
              <p v-if="recipe.tested != '0'" class="">
                <span v-for="star in Array.from({length: parseInt(recipe.rating)}, (x, i) => i)" :key="star">⭐</span>
              </p>

              <p v-else style="opacity: .2;">
                <span>⭐</span>
              </p>
            </div>

            <div class="inside-buttons w-100">
              <a v-if="recipe.url.length > 0" class="btn btn-sm btn-primary btn-nice px-3 mr-3" :href="recipe.url" target="_blank">Gå till receptsida</a>
              <button v-if="recipe.comment.length > 0" @click="showComment = !showComment" class="btn btn-sm btn-secondary btn-nice">Visa kommentar</button>
            </div>
          </div>
        </div>

        <div class="outside-buttons w-100 mt-4">
          <a v-if="recipe.url.length > 0" class="btn btn-sm btn-primary btn-nice px-3 mr-3" :href="recipe.url" target="_blank">Gå till receptsida</a>

          <button v-if="recipe.comment.length > 0" @click="showComment = !showComment" class="btn btn-sm btn-secondary btn-nice">Visa kommentar</button>
        </div>

        <div v-if="showComment" class="card mt-4">
          <div v-html="recipe.comment" class="card-body"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppRecipeCard',

  props: {
    recipe: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      showComment: false
    }
  }
}
</script>
